<template>
  <div id="dashboard_pagecontainer_master" class="canna_personalInfo_pageZone">

    <div style="position: relative;display: flex;flex-direction: column;height: 100%; overflow: hidden;">

      <template v-if="(currentZona_HasHeader===true && $q.screen.gt.sm)">
      <div id="personalinfoHeader noselect" class="ppi_header">
        <div id="personalinfoHeader_container" class="ppi_header_container">
          <div class="pi_zoneTab noselect" >
            <div class="tab_title" :class="{ 'pi_zoneTab_active':(currentZonaName==='a')}">Búsqueda de trabajo</div>
            <div class="tab_description" v-html="zonaA_Label"></div>
          </div>

          <div class="pi_zoneTab noselect">
            <div class="tab_title" :class="{ 'pi_zoneTab_active':(currentZonaName==='b')}">Dirección y transporte</div>
            <div class="tab_description" v-html="zonaB_Label"></div>
          </div>

          <div class="pi_zoneTab noselect">
            <div class="tab_title" :class="{ 'pi_zoneTab_active':(currentZonaName==='c')}">Uniformes</div>
            <div class="tab_description" v-html="zonaC_Label"></div>
          </div>

          <div class="pi_zoneTab noselect">
            <div class="tab_title" :class="{ 'pi_zoneTab_active':(currentZonaName==='d')}">Aptitudes</div>
            <div class="tab_description" v-html="zonaD_Label"></div>
          </div>
        </div>
      </div>
      </template>

      <!-- Zona de trabajo -->
      <div class="ppi_pageZone" v-if="initCatData===true">
        <keep-alive>
          <router-view />
        </keep-alive>
      </div>

    </div>

    <!-- Loaders --> 
    <q-inner-loading :showing="loading_Process" class="app_innerLoader" style="top: 0px; z-index: 8000;position: fixed;">
      <q-spinner-orbit size="100px" color="indigo" />
    </q-inner-loading>

    <q-inner-loading :showing="zloadingData" style="top: 0px; z-index: 8000;position: fixed;">
      <q-spinner-gears :size="(pageSize === 'sm' || pageSize === 'xs') ? '90px' : '120px' " color="cannaLoading" />
    </q-inner-loading>

  </div>
</template>

<script>
import { requestPack } from './../../../boot/axios' // APP code 

import logOut_Mixin from './../../../mixins/requestMix.js'
import myplatform_Mixin from './../../../mixins/platformMix.js'
import helperBase_Mixin from './../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../mixins/helperCatsMix.js'

import { sectionRutas } from './../../../mixins/questionario/personalinfo.js'

export default {
  name: 'personal_screen',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin
  ],
  components:{

  },
  data () { 
    return {
      version: '1.0.1',
      aa_pagesRef: sectionRutas,
      aa_headerZones: ['a','b','c','d'],
      debugEls: false,
      // --------
      initCatData: false,
      // --------
      zinitScreen: false,
      zloadingData: false,
      loading_Process:false
    }
  },
  computed: {
    currentZona () {
      return 0 
    },
    currentZonaName (){
      var nameRuta  = this.$route.name 
      var indexRuta = this.aa_pagesRef.findIndex( item => item.routeName === nameRuta )
      if (indexRuta<0) { 
        return -1
      } else {
        var nameZone = this.aa_pagesRef[indexRuta].zona
        return nameZone
      }
    },
    currentZona_HasHeader(){ 
      var testHeader = ( this.aa_headerZones.indexOf(this.currentZonaName) >=0 ) ? true : false
      return testHeader
    },
    testProfile(){
      return this.getProfileData
    },
    zonaA_Label () {
      var strRet = 'Esta sección aún <br> necesita información'
      try{
        if (this.getProfileData.status){
          if ( this.getProfileData.status>=12 && this.getProfileData.status<16 ){
            strRet  = 'En progreso'
          } else if (this.getProfileData.status>=16){
            strRet = 'Completo'
          }
        } else {
          strRet = 'En progreso.'
        }
      } catch (errx) {
        strRet = 'En progreso.' 
      }
      return strRet
    },
    zonaB_Label () {
      var strRet = 'Esta sección aún <br> necesita información'
      try{
        if ( this.getProfileData.status<16){
          strRet = 'Esta sección aún <br> necesita información'
        } else  if ( this.getProfileData.status>=16 && this.getProfileData.status<20 ){
          strRet = 'En progreso'
        } else if (this.getProfileData.status>=20){
          strRet = 'Completo'
        }
      } catch (errx) {
        strRet = 'En progreso.'
      }
      return strRet
    },
    zonaC_Label () {
      var strRet = 'Esta sección aún <br> necesita información'
      try{
        if ( this.getProfileData.status<20){
          strRet = 'Esta sección aún <br> necesita información'
        } else  if ( this.getProfileData.status>=20 && this.getProfileData.status<21 ){
          strRet = 'En progreso'
        } else if (this.getProfileData.status>=21){
          strRet = 'Completo'
        }
      } catch (errx) {
        strRet = 'En progreso.'
      }
      return strRet
    },
    zonaD_Label () {
      var strRet = 'Esta sección aún <br> necesita información'
      try{
        if ( this.getProfileData.status<21){
          strRet = 'Esta sección aún <br> necesita información'
        } else  if ( this.getProfileData.status>=21 && this.getProfileData.status<24 ){
          strRet = 'En progreso'
        } else if (this.getProfileData.status>=24){
          strRet = 'Completo'
        }
      } catch (errx) {
        strRet = 'En progreso.'
      }
      return strRet
    }
  },
  watch: {

  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- dashMainScreen -- test')
    },
    showLoadr ( typeUse, showVal ) {
      if (typeUse === 1 ) {
        this.loading_Process =  (showVal === true )  ? true : false 
      } else if (typeUse === 2 ) {
        this.zloadingData =  (showVal === true )  ? true : false 
      }
      this.$forceUpdate()
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- dashMainScreen -- beforeCreate')
    this.initCatData = false
  },
  created () {
    this.$cannaDebug('-- dashMainScreen -- created')
    this.zloadingData = true
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- dashMainScreen --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- dashMainScreen -- mounted')
    if (this.initCatData===false){

      if (this.$store.state.myapp.helperCatalog.length<=0){ 
        await this.helper_getAllCatalogs().then(respx =>{
          this.$cannaDebug('-- dashMainScreen -- get All Catalogs -- finished ')
        })
        await this._waitRequestProc(1500)
      }

      this.initCatData = true
    }
    this.zloadingData = false
  },
  beforeUpdate () {
    // this.$cannaDebug('-- dashMainScreen -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- dashMainScreen -- updated')
  },
  activated () {
    this.$cannaDebug('-- dashMainScreen -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- dashMainScreen -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- dashMainScreen -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- dashMainScreen -- destroyed')
  }
}
</script>
